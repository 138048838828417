<template> 
    <HeaderIndex /> 
    <header id="header" class="header">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="text-container">
                        <h1 class="h1-large">AVISO LEGAL Y POLÍTICA PRIVACIDAD</h1>
                    </div> 
                </div>
            </div>
        </div>
        <br>
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">
                    <p>En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, se hace constar que esta página corresponde a la entidad: </p>
                    <b>¿Quién es el responsable del tratamiento de datos?</b>
                    <p>Razón social: MUDANZAS RETO<br>
                       Email: mudanzas@mudanzasreto.com<br>
                       Teléfono: 642 57 52 21</p>
                    <b>Delegado de protección de datos:</b>
                    <p>Puedes dirigirte de cualquier forma para comunicarte con nosotros.Nos reservamos el derecho a modificar o adaptar la presente Política de Privacidad en cualquier momento. Te recomendamos revisar la misma, y si te has registrado y accedes a tu cuenta o perfil, se te informará de las modificaciones.</p>
                    <b>¿Qué datos recopilamos a través de la Web?</b>
                    <p>No recopilamos ningún dato a través de la web.</p>
                    <b>¿Con qué finalidades vamos a tratar sus datos personales?</b>    
                    <p>No tratamos  y no recopilamos datos.</p>
                    <b>¿Cuál es la legitimación para el tratamiento de datos?</b>
                    <p>No tratamos  y no recopilamos datos.</p>
                    <b>USUARIOS</b>
                    <p>El acceso y/o uso de este portal de MUDANZASRETO.COM atribuye la condición de USUARIO, 
                        que acepta, 
                        desde dicho acceso y/o uso, 
                        las Condiciones Generales de Uso aquí reflejadas. 
                        Las citadas Condiciones serán de aplicación independientemente de las Condiciones Generales de Contratación que en su caso resulten de obligado cumplimiento. </p>
                    <b>USO DEL PORTAL</b>
                    <p>mudanzasreto.com proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, "los contenidos") en Internet pertenecientes a mudanzasreto.com o a sus licenciantes a los que el USUARIO pueda tener acceso.
                        El USUARIO asume la responsabilidad del uso del portal. 
                        El USUARIO se compromete a hacer un uso adecuado de los contenidos y servicios que mudanzasreto.com puede ofrece a través de su portal y con carácter enunciativo pero no limitativo,
                        a no emplearlos para (i) incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público; (ii) difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal,
                        de apología del terrorismo o atentatorio contra los derechos humanos; (iii) provocar daños en los sistemas físicos y lógicos de mudanzasreto.com, de sus proveedores o de terceras personas,
                        introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados; 
                        (iv) intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes. 
                        mudanzasreto.com se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud
                        o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación. 
                        En cualquier caso, mudanzasreto.com no será responsable de las opiniones vertidas por los usuarios a través de los foros, chats, u otras herramientas de participación. </p>
                    <b>PROPIEDAD INTELECTUAL E INDUSTRIAL</b>
                    <p>mudanzasreto.com por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de su página web junto con las imagenes que son propiedad de Google  presentadas en el portal web,
                        así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, 
                        programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de mudanzasreto.com o bien de Google y sus licenciantes. 
                        Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública,
                        incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico,
                        sin la autorización de mudanzasreto.com. El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de mudanzasreto.com y Google.
                        Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado.
                        El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el las páginas de mudanzasreto.com.</p>
                    <b>DIRECCIONES IP</b>
                    <p>No detectamos en ningun momento direccion IP de USUARIO.</p>
                    <b>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</b>
                    <p>mudanzasreto.com no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión 
                        de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo. </p>
                    <b>MODIFICACIONES</b>
                    <p>mudanzasreto.com se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como 
                        la forma en la que éstos aparezcan presentados o localizados en su portal. </p>
                    <b>ENLACES</b>
                    <p>En el caso de que en mudanzasreto.com se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, mudanzasreto.com no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso mudanzasreto.com asumirá
                        responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material
                        o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet. Igualmente la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas. </p>
                    <b>DERECHO DE EXCLUSIÓN</b>
                    <p>mudanzasreto.com se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso. </p>
                    <b>GENERALIDADES</b>
                    <p>mudanzasreto.com perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho. </p>
                    <b>MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</b>
                    <p>mudanzasreto.com podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas. </p>
                    <b>SEGURIDAD</b>
                    <p>El sitio web utiliza técnicas de seguridad de la información generalmente aceptadas en la industria, tales como firewalls, procedimientos de control de acceso y mecanismos criptográficos, todo ello con el objeto de evitar el acceso no autorizado a los datos. Para lograr estos fines, el usuario/cliente acepta que el prestador obtenga datos para efectos de la correspondiente autenticación de los controles de acceso.</p>
                    <p>Todo proceso de contratación o que conlleve la introducción de datos personales de carácter alto (salud, ideología,…) serán siempre transmitidos mediante protocolo de comunicación segura (Https://,…), de tal forma que ningún tercero tenga acceso a la información transmitida vía electrónica.</p>
                    <b>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</b>
                    <p>La relación entre mudanzasreto.com y el USUARIO se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y tribunales que corresponda.</p>
                </div>
            </div>
        </div>
    </header> 
    <FooterIndex />
</template>
<script>
import HeaderIndex from '@/components/IndexPage/HeaderIndex';
import FooterIndex from '@/components/IndexPage/FooterIndex';
export default{
    components: { HeaderIndex, FooterIndex },
}
</script>
